import validateElement from './utils/validation';

const inviteEmailInput = document.querySelector('#invite_email');

IMask(inviteEmailInput, {
  mask: /^[A-Za-z0-9@._\-]{1,254}$/
});

function handeSubmit(event) {
  const form = event.target.closest('.modal-form');

  const validations = [
    {
      pattern: /^[A-Za-z0-9@._\-]{1,254}$/,
      element: inviteEmailInput
    }
  ];

  validations.forEach(({ pattern, element }) => {
    if (!validateElement(element, pattern)) event.preventDefault();
  });
}

document.querySelector('#send_invite').addEventListener('click', handeSubmit);