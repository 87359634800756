function validateElement(element, pattern) {
  const isValidated = element.value.match(pattern) || (!element.required && !element.value.length);
  
  if (isValidated) {
    element.classList.remove('form-validation-error');
  } else {
    element.classList.add('form-validation-error');
  }

  return isValidated;
}

function handleAvatarChange(e) {
  const { currentTarget } = e;
  const { imageId } = currentTarget.dataset;
  const imageElement = document.getElementById(imageId);
  const currentForm = currentTarget.closest('.modal-window');
  const initialFileSource = imageElement.src;
  
  const isvalidFormat = currentTarget.files[0].type.match(/^image\/png|jpeg$/);
  const fileSize = currentTarget.files[0].size / 1024 / 1024;

  const reader = new FileReader();

  if (!isvalidFormat) {
    handleImageError('Недопустимый формат файла');
    return;
  }

  if (fileSize > 3) {
    handleImageError('Размер файла не должен превышать 3 МБ');
    return;
  }

  reader.onload = async event => {
    try {
      await imageExists(event.target.result);
      imageElement.src = event.target.result;
      currentForm.querySelector('.uk-alert-close')?.click();
    } catch (errorMessage) {
      handleImageError(errorMessage);
    }
  };

  reader.readAsDataURL(currentTarget.files[0]);

  function handleImageError(errorMessage) {
    currentTarget.value = null;
    imageElement.src = initialFileSource;
    currentForm.querySelector('.alerts').innerHTML = `
      <div class="uk-alert-danger uk-animation-fade" id="modal-alert" uk-alert>
        <a class="uk-alert-close" uk-close=""></a>
        <p>${errorMessage}</p>
      </div>
    `;
  }
}

function imageExists(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = () => reject('Недопустимый формат файла');
    img.src = url;
  });
}

export { handleAvatarChange };
export default validateElement;